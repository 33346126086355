<template>
  <div class="page-content">
    <div class="employee main-content">
      <div class="page-header">
        <h1>Staff Information</h1>
        <StaffInfoForm :viewMode="true":eventName="'updateEmployee'" @updateEmployee="updateEmployee"/>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import StaffInfoForm from '@/components/hr/StaffInfoForm.vue';
import Common from '@/lib/common';
import Hr from '@/lib/hr';

export default {
  name: 'Employee',
  components: {
    StaffInfoForm,
  },
  mounted(){
    this.loadEmployee();
  },
  methods:{
    async loadEmployee(){
      const loginInfo = Common.getLoginInfo();
      try{
        const employeeId = this.$route.params.id;
        const employee = await Hr.loadEmployee(this.apiUrl, employeeId, loginInfo);
        this.setCurrentEmployee(employee);
      }catch(err){
        console.log(err);
      }
    },
    async updateEmployee(form){
      try{
        const loginInfo = Common.getLoginInfo();
        const employeeId = this.$route.params.id;
        const updateFields = Object.assign({}, form);
        const employee = await Hr.updateEmployee(this.apiUrl, employeeId, updateFields, loginInfo);
      }catch(err){
        console.log(err);
      }
    },
    ...mapActions(['setCurrentEmployee']),
  },
  computed: mapState({
    apiUrl: state => state.apiUrl,
  }),
}
</script>

<style lang="scss">
.page-header{
  h1{
    margin-bottom: 50px;
  }
}
</style>
